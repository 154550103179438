import { BrowserProvider, Contract, Wallet, ethers } from 'ethers'
import { BankTellerTrailOfBitsV1Abi } from './BankTellerTrailOfBitsV1Abi'
import USDCAbi from './UsdcAbi'
import { NETWORKS } from '../utils/utils'

export async function broadcastRefundToBanktTeller(
  signature,
  provider,
  refundApproval
) {
  if (provider) {
    const browserProvider = new BrowserProvider(provider)
    const signer = await browserProvider.getSigner()
    const bankTellerContract = getBankTellerContract(signer)

    const { v, r, s } = calculateSignatureVRS(signature)
    console.log('broadcastRefundToBanktTeller3334', { v, r, s, bankTellerContract })
    const tx = await bankTellerContract.refundEventFunds(
      refundApproval,
      v,
      r,
      s
    )
    const receipt = await tx.wait()
    console.log('broadcastToBanktTeller', { tx: tx, receipt: receipt })
    return receipt
  }
}
export async function getCurrentUsdcBalance(owner, provider) {
  const browserProvider = new BrowserProvider(provider)
  const signer = await browserProvider.getSigner()
  const usdcContract = getUsdcContract(signer)
  const balance = await usdcContract.balanceOf(owner)
  return Number(balance)
}


export async function getCurrentAllowance(owner, provider) {
  console.log('getCurrentAllowance1', { owner, provider })
  const bankTellerContractAddress =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? NETWORKS.BASE.BANKTELLER_CONTRACT_ADDRESS
    : NETWORKS.BASE_SEPOLIA.BANKTELLER_CONTRACT_ADDRESS

  const browserProvider = new BrowserProvider(provider)
  const signer = await browserProvider.getSigner()
  const usdcContract = getUsdcContract(signer)
  const allowance = await usdcContract.allowance(owner, bankTellerContractAddress)
  console.log('allowance res', { allowance })
  return Number(allowance)
}

function getUsdcContract(signer) {
  const usdcContract = new Contract(
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? NETWORKS.BASE.USDC_ADDRESS
      : NETWORKS.BASE_SEPOLIA.USDC_ADDRESS,
    USDCAbi,
    signer
  )
  return usdcContract
}

function getBankTellerContract(signer) {
  const bankTellerContract = new Contract(
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? NETWORKS.BASE.BANKTELLER_CONTRACT_ADDRESS
      : NETWORKS.BASE_SEPOLIA.BANKTELLER_CONTRACT_ADDRESS,
    BankTellerTrailOfBitsV1Abi,
    signer
  )
  return bankTellerContract
}

function calculateSignatureVRS(signature) {
  const r = signature.substring(0, 66)
  const s = '0x' + signature.substring(66, 130)
  let v = parseInt(signature.substring(130, 132), 16) // Convert to integer
  // Depending on how the signature was made, you might need to adjust v
  if (v < 27) {
    v += 27
  }
  // Convert v back to hex format if necessary
  v = '0x' + v.toString(16)
  /**
   * alternative
   */
  const sig = ethers.Signature.from(signature)
  // return [v, r, s]
  console.log('calculateSignatureVRS 1', { sig })

  return sig
}
