import { createAppKit } from '@reown/appkit/react'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'

import { WagmiProvider } from 'wagmi'
import { base, baseSepolia } from '@reown/appkit/networks'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createSIWE } from '../services/siweService'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Your WalletConnect Cloud project ID
const projectId = 'f9b7f121573a0a15d467393e8542df07'

const FRONTEND_URL =
  process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? 'https://staging.tippingpoint.app'
    : 'http://localhost:3001'

const metadata = {
  name: 'Tipping Point',
  description: '',
  url: FRONTEND_URL,
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const chains =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? [base] : [baseSepolia]

// export const config = defaultWagmiConfig({
//   chains,
//   projectId,
//   metadata,
//   ssr: true,
//   auth: {
//     email: false,
//     socials: [],
//   },
// })

const wagmiAdapter = new WagmiAdapter({
  networks: chains,
  projectId,
})

export const config = wagmiAdapter.wagmiConfig

// 3. Create a SIWE configuration object
const siweConfig = createSIWE(chains.map((chain) => chain.id))

// 4. Create modal
// createWeb3Modal({ wagmiConfig: config, projectId, siweConfig, metadata })

createAppKit({
  adapters: [wagmiAdapter],
  networks: chains,
  metadata: metadata,
  projectId,
  features: {
    analytics: true,
    socials: ['google'],
    email: true,
    // featuredWalletIds: ['fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'],
    // collapseWallets: true,
    connectMethodsOrder: ['email', 'social', 'wallet'],
  },
  siweConfig,
})

export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
