import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, Typography, Divider, Stack } from '@mui/material'
import logomark from '../../assets/Logomark.png'
import styles from '../../styles/Footer.module.css'
import tippingPoint from '../../assets/TippingPoint.png'
import twitterIcon from '../../assets/TwitterIcon.png'
import discordIcon from '../../assets/DiscordIcon.png'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'

const TippingPointLogoComponent = (props) => {
  const { justifyContent } = props

  return (
    <>
      <Stack
        direction={'row'}
        spacing={{ xs: 1, lg: 0 }}
        alignItems={'center'}
        justifyContent={justifyContent}
      >
        <img
          src={logomark}
          alt="logomark"
          className={styles.TippingPointLogomark}
        />
        <img
          src={tippingPoint}
          alt="tippingPoint"
          className={styles.TippingPointLogoText}
        />
      </Stack>
    </>
  )
}

const SocialMediaLinks = (props) => {
  const { justifyContent } = props

  return (
    <>
      <Stack
        direction={'row'}
        spacing={{ xs: 1.25, md: 2 }}
        justifyContent={justifyContent}
      >
        {/* <img src={facebookIcon} alt='facebookIcon' className={styles.SocialMediaLinks} /> */}
        <img
          onClick={() =>
            window.open('https://x.com/TPdotApp', '_blank').focus()
          }
          src={twitterIcon}
          alt="twitterIcon"
          className={styles.SocialMediaLinks}
        />
        <InstagramIcon
          onClick={() =>
            window
              .open('https://www.instagram.com/tipping_point_app/', '_blank')
              .focus()
          }
          alt="instagramIcon"
          className={styles.SocialMediaLinks}
        />
        <YouTubeIcon
          onClick={() =>
            window
              .open('https://www.youtube.com/@TippingPointApp', '_blank')
              .focus()
          }
          alt="youtubeIcon"
          className={styles.SocialMediaLinks}
        />
        <LinkedInIcon
          onClick={() =>
            window
              .open(
                'https://www.linkedin.com/company/tipping-point-app/',
                '_blank'
              )
              .focus()
          }
          alt="linkedinIcon"
          className={styles.SocialMediaLinks}
        />
        <img
          onClick={() =>
            window.open('https://discord.gg/nemMetdSN5', '_blank').focus()
          }
          src={discordIcon}
          alt="discordIcon"
          className={styles.SocialMediaLinks}
        />
      </Stack>
    </>
  )
}

const FooterType1 = () => {
  const navigate = useNavigate()

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Divider className={styles.FooterDivider} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={4}>
              <Stack
                direction={'column'}
                spacing={{ xs: 3 }}
                alignItems={'center'}
              >
                <TippingPointLogoComponent justifyContent={'flex-start'} />
                <SocialMediaLinks justifyContent={'flex-start'} />
              </Stack>
            </Grid>
            <Grid item xs={4} className={styles.FooterLinkBlock}>
              <Stack
                direction={'column'}
                spacing={{ xs: 1 }}
                alignItems={'center'}
              >
                <div className={styles.FooterHeader}>Company</div>
                <div
                  onClick={() => {
                    navigate('/aboutus')
                    window.scrollTo(0, { behavior: 'smooth' })
                  }}
                  className={styles.FooterSubtext}
                >
                  About Us
                </div>
                <div
                  onClick={() => {
                    navigate('/howitworks')
                    window.scrollTo(0, { behavior: 'smooth' })
                  }}
                  className={styles.FooterSubtext}
                >
                  How It Works
                </div>
                <div
                  onClick={() => {
                    navigate('/getstarted')
                    window.scrollTo(0, { behavior: 'smooth' })
                  }}
                  className={styles.FooterSubtext}
                >
                  Get Started
                </div>
                <div
                  onClick={() => {
                    navigate('/faq')
                    window.scrollTo(0, { behavior: 'smooth' })
                  }}
                  className={styles.FooterSubtext}
                >
                  FAQ
                </div>
                {/* <div className={styles.FooterSubtext}>Blog</div> */}
              </Stack>
            </Grid>
            {/* <Grid item xs={4}  className={styles.FooterLinkBlock}>
              <Stack direction={'column'} spacing={{xs: 1, }}>
                <div className={styles.FooterHeader}>Legal</div>
                <div className={styles.FooterSubtext}>Privacy Policy</div>
                <div className={styles.FooterSubtext}>T&C</div>
                <div className={styles.FooterSubtext}>Cookie Policy</div>
                <div className={styles.FooterSubtext}>Refunds</div>
                <div className={styles.FooterSubtext}>DCMA</div>
              </Stack>
            </Grid> */}
            <Grid item xs={4} className={styles.FooterLinkBlock}>
              <Stack
                direction={'column'}
                spacing={{ xs: 1 }}
                alignItems={'center'}
              >
                <div className={styles.FooterHeader}>Contact</div>
                {/* <div className={styles.FooterSubtext}>My Account</div>
                <div className={styles.FooterSubtext}>FAQ</div>
                <div className={styles.FooterSubtext}>Support Desk</div> */}
                <div
                  onClick={() => {
                    navigate('/contactus')
                    window.scrollTo(0, { behavior: 'smooth' })
                  }}
                  className={styles.FooterSubtext}
                >
                  Contact Us
                </div>
                {/* <div className={styles.FooterSubtext}>Press Releases</div> */}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={styles.FooterDivider} />
        </Grid>
        <Grid item xs={12}>
          <div className={styles.FooterBottomText}>
            @2024 Tipping Point. All rights reserved.
          </div>
        </Grid>
      </Grid>
    </>
  )
}

const FooterType2 = () => {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 4, lg: 4 }}>
        <Grid item xs={12}>
          <Divider className={styles.FooterDivider} />
        </Grid>
        <Grid item xs={12}>
          <TippingPointLogoComponent justifyContent={'center'} />
        </Grid>
        <Grid item xs={12}>
          {/* <Grid container direction={'row'} spacing={{xs: 2, md: 10, lg: 10}} justifyContent={'center'}>
            <Grid item xs={3.1} md={1.5}>
              <div className={styles.FooterSubtext}>About Us</div>
            </Grid>
            <Grid item xs={3.1} md={1.5}>
              <div className={styles.FooterSubtext}>Careers</div>
            </Grid>
            <Grid item xs={3.1} md={1.5}>
              <div className={styles.FooterSubtext}>News</div>
            </Grid>
            <Grid item xs={3.1} md={1.5}>
              <div className={styles.FooterSubtext}>Company</div>
            </Grid>
            <Grid item xs={3.1} md={1.5}>
              <div className={styles.FooterSubtext}>Blog</div>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <SocialMediaLinks justifyContent={'center'} />
        </Grid>
        <Grid item xs={12}>
          <Divider className={styles.FooterDivider} />
        </Grid>
        <Grid item xs={12}>
          <div className={styles.FooterBottomText}>
            @2024 Tipping Point. All rights reserved.
          </div>
        </Grid>
      </Grid>
    </>
  )
}

const FooterType3 = () => {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 6 }}>
        <Grid item xs={12}>
          <Stack direction={'column'} spacing={{ xs: 4, md: 0 }}>
            <Divider className={styles.FooterDivider} />
            <Container className={styles.FooterType3TopLogo}>
              <TippingPointLogoComponent justifyContent={'center'} />
            </Container>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 4 }}>
            <Grid item xs={6} md={3} className={styles.FooterLinkBlock}>
              <Stack direction={'column'} spacing={{ xs: 1, md: 2 }}>
                <div className={styles.FooterHeader}>Resources</div>
                <div className={styles.FooterSubtext}>Library</div>
                <div className={styles.FooterSubtext}>Blog</div>
                <div className={styles.FooterSubtext}>Help Center</div>
              </Stack>
            </Grid>
            <Grid item xs={6} md={3} className={styles.FooterLinkBlock}>
              <Stack direction={'column'} spacing={{ xs: 1, md: 2 }}>
                <div className={styles.FooterHeader}>Company</div>
                <div className={styles.FooterSubtext}>About Us</div>
                <div className={styles.FooterSubtext}>Careers</div>
                <div className={styles.FooterSubtext}>Company</div>
              </Stack>
            </Grid>
            <Grid item xs={6} md={3} className={styles.FooterLinkBlock}>
              <Stack direction={'column'} spacing={{ xs: 1, md: 2 }}>
                <div className={styles.FooterHeader}>Legal</div>
                <div className={styles.FooterSubtext}>Privacy Policy</div>
                <div className={styles.FooterSubtext}>T&C</div>
                <div className={styles.FooterSubtext}>Cookie Policy</div>
              </Stack>
            </Grid>
            <Grid item xs={6} md={3} className={styles.FooterLinkBlock}>
              <Stack direction={'column'} spacing={{ xs: 1, md: 2 }}>
                <div className={styles.FooterHeader}>Contact</div>
                <div className={styles.FooterSubtext}>FAQ</div>
                <div className={styles.FooterSubtext}>Contact Us</div>
                <div className={styles.FooterSubtext}>Press Releases</div>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={styles.FooterDivider} />
        </Grid>
        <Grid item xs={12} className={styles.FooterType3BottomLogo}>
          <TippingPointLogoComponent justifyContent={'flex-start'} />
        </Grid>
        <Grid item xs={12}>
          <div className={styles.FooterBottomTextType3}>
            @2024 Tipping Point. All rights reserved.
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export { FooterType1, FooterType2, FooterType3 }
